



















import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsFunctionType, ThingsEntityType } from '@/model/enum';
import ThingsAttributeService from '@/service/things-attribute';
import ThingsEventService from '@/service/things-event';
import ThingsMethodService from '@/service/things-method';
import ThingsFeatureService from '@/service/things-feature';
import DeviceTypeService from '@/service/device-type';
import { thingsModelFunctionFilter } from '@/filter';

@Component
export default class ThingsTemplateFunctionEditComponent extends BaseComponent {
    functionType: ThingsFunctionType = null;
    allDataList: Array<any> = null;
    loading: boolean = false;
    dialogVisible: boolean = false;
    @Prop()
    entityId: string;
    @Prop()
    entityType: ThingsEntityType;

    get DialogTitle(): string {
        return thingsModelFunctionFilter(this.functionType);
    }

    dialogOpen(functionType: ThingsFunctionType, selectFunctionList: Array<any>) {
        this.dialogVisible = true;
        this.functionType = functionType;
        let getAllService: (selectFunctionList: Array<any>) => Promise<any> = null;
        switch (this.functionType) {
            case ThingsFunctionType.EVENT:
                getAllService = ThingsEventService.getAll;
                break;
            case ThingsFunctionType.METHOD:
                getAllService = ThingsMethodService.getAll;
                break;
            case ThingsFunctionType.ATTRIBUTE:
                getAllService = ThingsAttributeService.getAll;
                break;
            case ThingsFunctionType.FEATURE:
                getAllService = ThingsFeatureService.getAll;
                break;
        }
        this.loading = true;
        getAllService(selectFunctionList).then(res => {
            this.allDataList = res;
        }).finally(() => {
            this.loading = false;
        });
    }

    dialogOK(): Promise<any> {
        let saveService: (params) => Promise<any> = null;
        switch (this.functionType) {
            case ThingsFunctionType.EVENT:
                saveService = ThingsEventService.saveFunction;
                break;
            case ThingsFunctionType.METHOD:
                saveService = ThingsMethodService.saveFunction;
                break;
            case ThingsFunctionType.ATTRIBUTE:
                saveService = ThingsAttributeService.saveFunction;
                break;
            case ThingsFunctionType.FEATURE:
                saveService = DeviceTypeService.addFeature;
                break;
        }
        let params = null;
        if (this.functionType === ThingsFunctionType.FEATURE) {
            params = {
                typeId: this.entityId,
                ids: _.map(_.filter(this.allDataList, item => item.check), item => item.value)
            };
        } else {
            params = {
                id: this.entityId,
                type: this.entityType,
                attrIds: _.map(_.filter(this.allDataList, item => item.check), item => item.value)
            };
        }
        return saveService(params).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK', ret);
        }).catch(err => {
            throw err;
        });
    }

    dialogClose(): void {
        this.dialogVisible = false;
    }
}
