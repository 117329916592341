var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component",
      attrs: { title: "添加" + _vm.DialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "things-template-function-edit-component form-edit-component"
        },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c("jtl-transform-component", {
                attrs: {
                  "function-type": _vm.functionType,
                  "all-data-list": _vm.allDataList
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }